<template>
  <div class="sys-config-page" v-loading="loading">
    <div class="sys-config-page-conten">
      <fm-title title-text="系统配置" :titleMenus="titleMenus" @clickTitleMenu="clickTitleMenu"></fm-title>
      <div class="content">
        <div class="config-item" v-for="configItem in datas" :key="configItem.key">
          <div class="c-label">{{configItem.label}}</div>
          <div class="c-value">
            <fm-input-new
              size="small"
              v-if="configItem.editConfig && configItem.editConfig.type === 'input'"
              @change="valueChange($event, configItem)"
              v-model="configItem.value">
            </fm-input-new>
            <fm-switch
              v-if="configItem.editConfig && configItem.editConfig.type === 'switch'"
              @change="valueChange($event, configItem)"
              size="small" v-model="configItem.value">
              <span slot="on">{{configItem.editConfig.label[1]}}</span>
              <span slot="off">{{configItem.editConfig.label[0]}}</span>
            </fm-switch>
          </div>
          <div class="c-remark">{{configItem.remark}}</div>
          <div class="c-default">{{configItem.defaultValue}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  sysConfigRequest,
  authRequest
} from '@/api'

import {
  getOtherSysConfig
} from '@/sys'

export default {
  data () {
    return {
      datas: [],
      loading: false
    }
  },
  computed: {
    titleMenus () {
      return this.currentUserId === 1 ? [{key: 'user', label: '用户处理'}, {key: 'position', label: '职务处理'}] : []
    },
    currentUserId () {
      return this.$store.getters.currentUserId
    }
  },
  methods: {
    async clickTitleMenu (key) {
      this.loading = true
      if (key === 'user') {
        await authRequest.dealUser()
      } else {
        await authRequest.dealPosition()
      }
      this.loading = false
    },
    async loadData () {
      let res = await sysConfigRequest.get({type: 'sysConfig'})
      let dataList = []
      getOtherSysConfig().forEach(v => {
        let value = res.find(v1 => v1.configKey === v.key)
        v.dbData = value
        if (value && value.value) {
          value = JSON.parse(value.value)
        } else {
          value = v.defaultValue
        }
        dataList.push(Object.assign(JSON.parse(JSON.stringify(v)), {
          value: value
        }))
      })
      this.datas = dataList
    },
    async valueChange (value, configItem) {
      await sysConfigRequest.add({
        type: 'sysConfig',
        configKey: configItem.key,
        name: configItem.label,
        remark: configItem.remark,
        value: JSON.stringify(value)
      })
      this.loadData()
    }
  },
  created () {
    this.loadData()
  }
}
</script>

<style scoped lang="less">
.sys-config-page {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .sys-config-page-conten {
    margin: 20px;
    background: #fff;
    border-radius: 8px;
  }
}
.config-item {
  min-height: 5rem;
  border-bottom: 1px solid #eee;
}
.config-item {
  display: flex;
  min-height: 3rem;
  border-bottom: 1px solid #eee;
  padding: 1rem;
  .c-label {
    width: 15rem;
    display: flex;
    align-items: center;
  }
  .c-remark {
    flex: 3;
    display: flex;
    align-items: center;
  }
  .c-value {
    width: 15rem;
    display: flex;
    margin-left: 2rem;
    align-items: center;
  }
}
</style>